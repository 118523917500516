.StripeElement {
    padding-bottom: .3rem;
    padding-top: .5rem;
    border-bottom: 1px solid;
    border-color: #D4E1EA;
}

.StripeElement--focus {
    border-bottom: 1.5px solid;
    border-color: #3bc3a8;
}
